$NavBar_Blue:#41BBE5;
$CardBorder_White:#E0EAEE;
$White:#FFFFFF;
$RaffleClosesOn_OffWhite:#F9F9F9;
$Black:#10111C;
$RaffleLine_Green:#19A520;
$RaffleAmount_FillBlue:#EDFAFF;
$RaffleAmount_TextBlue:#00A8FF;
$ProceedButton_Disabled:linear-gradient(122.15deg, #2DAFF4 0%, #6FD5F8 100%);
$Benefit_Text: #4f5056;
$RaffleContributor_Count: linear-gradient(154.13deg, #bbda73 0%, #65be6a 100%);