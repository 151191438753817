@import "./colors.scss";
@import "./fonts.scss";
@mixin title {
  color: $Black;
  font-family: $CIRCULAR_STD;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 24px;
}

@mixin title-mobile {
  color: $Black;
  font-family: $CIRCULAR_STD_SEMI_BOLD;
  line-height: 30px;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 30px;
}

@mixin benefit {
  color: $Benefit_Text;
  font-family: $OPEN_SANS;
  font-size: 12px;
  line-height: 18px;
}

@mixin contributor-count {
  color: $Benefit_Text;
  font-family: $OPEN_SANS;
  font-size: 12px;
  line-height: 15px;
}

@mixin select-raffle-amount-text {
  color: $Black;
  font-family: $OPEN_SANS_BOLD;
  font-size: 14px;
  line-height: 18px;
}

@mixin raffleClosesOn {
  opacity: 0.6;
  color: $Black;
  font-family: $OPEN_SANS_SEMI_BOLD;
  font-size: 10px;
  letter-spacing: 0.42px;
  line-height: 10px;
}

@mixin date-and-time {
  color: $Benefit_Text;
  font-family: $OPEN_SANS_BOLD;
  font-size: 12px;
  line-height: 15px;
}

@mixin raffle-line {
  color: $RaffleLine_Green;
  font-family: $OPEN_SANS;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
}

@mixin modal-small-input {
  height: 40px;
  width: 46%;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: none;
  margin-right: 5px;
  outline: none;
  margin-bottom: 16px;
  padding-left: 5px;
  color: $Black;
  font-family: $OPEN_SANS;
  font-size: 12px;
  line-height: 15px;
}

@mixin modal-input {
  height: 40px;
  width: 94%;
  border-radius: 4px;
  background-color: #f9f9f9;
  border: none;
  outline: none;
  margin-bottom: 16px;
  padding-left: 5px;
  color: $Black;
  font-family: $OPEN_SANS;
  font-size: 12px;
  line-height: 15px;
}

@mixin no-border-button {
  color: #1ca5d5;
  font-family: $OPEN_SANS_SEMI_BOLD;
  font-size: 12px;
  line-height: 15px;
  border: none;
  cursor: pointer;
  background-color: white;
}

@mixin modal-button {
  opacity: 0.4;
  border-radius: 4px;
  background-color: #41bbe5;
  padding: 12px 16px;
  border: none;
  color: #ffffff;
  font-family: $OPEN_SANS_SEMI_BOLD;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

@mixin lunch-title {
  color: #000000;
  font-family: $CIRCULAR_STD_BOOK;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.45px;
  line-height: 25px;
  text-align: center;

  //for mobiles
  @media screen and(max-width:479px) {
    font-size: 25px;
    letter-spacing: 0.62px;
    line-height: 30px;
    text-align: left;
    width: 278px;
  }
}

@mixin lunch-name {
  color: #7e53f9;
  font-family: $CIRCULAR_STD;
}
