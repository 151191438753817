@import "../../components/CommonStyles/colors.scss";
@import "../../components/CommonStyles/fonts.scss";

.Infocard {
  border-radius: 10px !important;
  border:none !important;
  padding-bottom: 17px;
  &-title {
    opacity: 0.6;
    color: white;
    font-family: $OPEN_SANS;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin: 18px 0px 6px 20px;
    margin-bottom:0.3750rem !important;
  }
  &-name {
    color: #FFFFFF;
    font-family: $CIRCULAR_STD;
    font-size: 16px;
    line-height: 14px;
    margin-left: 20px;
    margin-right:20px;
  }
  &-image{
    margin-top:12px;
    margin-right: 12px;
  }
  .flex-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
