* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "OpenSans-bold";
  src: url("./Assets/Fonts/OpenSans-Bold_0.ttf");
}
@font-face {
  font-family: "OpenSans-regular";
  src: url("./Assets/Fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Nobile";
  src: url("./Assets/Fonts/Nobile-Bold.ttf");
}
@font-face {
  font-family: "Circular-std-bold";
  src: url(".//Assets//Fonts//circular-bold.otf");
}
@font-face {
  font-family: "OpenSans-semibold";
  src: url(".//Assets/Fonts/OpenSans-Semibold_0.ttf");
}
@font-face{
  font-family: "Circular-std-semi-bold";
  src: url(".//Assets/Fonts/circular-medium.otf");
}
@font-face{
  font-family: "Circular-std-book";
  src: url("./Assets/Fonts/circular-book.otf");
}