@import "../CommonStyles/fonts.scss";

.explore-more-causes {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-header {
    color: #10111c;
    font-family: $CIRCULAR_STD;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.45px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  &-description {
    color: #4f5056;
    font-family: $OPEN_SANS;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .explore-image {
    width: 400px;
  }
}

//for mobiles
@media screen and(max-width:479px) {
  .explore-more-causes {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-100px);

    &-header {
      color: #10111c;
      font-family: $CIRCULAR_STD;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 24px;
      margin-bottom: 12px;
      margin-top: 40px;
      margin-left: 32px;
    }

    &-description {
      color: #4f5056;
      font-family: $OPEN_SANS;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 16px;
      margin-top: 5px;
      margin-left: 32px;
      margin-right: 32px;
    }

    .explore-image {
      width: 100%;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

//for tablets
@media screen and(min-width:700px) and(max-width:1000px) {
  .explore-more-causes {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-header {
      color: #10111c;
      font-family: $CIRCULAR_STD;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.45px;
      line-height: 22px;
      margin-bottom: 12px;
      margin-right: 15px;
    }

    &-description {
      color: #4f5056;
      font-family: $OPEN_SANS;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      margin-right: 15px;
    }

    .explore-image {
      width: 300px;
    }
  }
}

@media screen and (min-width:1000px) and (orientation:portrait) {
  .explore-more-causes {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-header {
      color: #10111c;
      font-family: $CIRCULAR_STD;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.45px;
      line-height: 22px;
      margin-bottom: 12px;
      margin-right: 15px;
    }

    &-description {
      color: #4f5056;
      font-family: $OPEN_SANS;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      margin-right: 15px;
    }

    .explore-image {
      width: 350px;
    }
  }
}